<template>
  <div>
    <p class="paySummary">Payment Summary</p>
    <!-- <div  >
  Lodddd...
</div> -->
    <Loading class="pb-5" v-if="loading" />

    <div v-else>
      <p
        v-if="loggedIn && route == 'checkout' && paymentSummary"
        class="p-checkout"
      >
        Item Quantity
        <span>{{ paymentSummary ? paymentSummary.total_quantity : "" }}</span>
      </p>
      <p class="p-checkout">
        Order Sub total
        <span>{{
          paymentSummary
            ? $helpers.formatPrice(orderData.order.sub_total)
            : $helpers.formatPrice(subTotal)
        }}</span>
      </p>
      <p
        v-if="loggedIn && route == 'checkout' && paymentSummary"
        class="p-checkout"
      >
        Shipping Rate
        <span>{{ $helpers.formatPrice(orderData.order.delivery_cost) }}</span>
      </p>
      <div class="div-demarc">
        <div v-if="loggedIn && route == 'checkout' && paymentSummary">
          <p class="p-checkout">
            Total
            <span>{{ $helpers.formatPrice(orderData.order.total_price) }}</span>
          </p>
        </div>
        <p v-if="route == 'cart'" class="text-dark">
          Delivery and taxes are calculated at checkout
        </p>
      </div>
      <!-- :disabled="cartSending" -->
      <button
        @click="redirectCheckout"
        v-if="route == 'cart'"
        :disabled="true"
        class="btn btn-checkout"
      >
        <!-- Proceed to checkout -->
        Products Unavailable
        <BtnLoading v-if="cartSending" class="btn-loading" />
      </button>
      <div v-else>
        <p class="disclaimer-font">
          Disclaimer: Please note that Imperium/Sterling bank will not be liable
          for any products purchased and installed by a non-licenced vendor by
          imperium.
        </p>

        <div class="mt--60" v-if="flutterProcessing">
          <Loading />
          <p>Processing with Flutterwave</p>
        </div>
        <div v-else>
          <p v-if="flutterErr" class="err">Flutterwave Payment Failed</p>
          <p v-if="flutterComplete" class="success">
            Flutterwave Payment Successful <br />
            Kindly check your orders page to view your order.
          </p>
          <!-- payWithFlutterwave() -->
          <button class="btn btn-checkout mt-0" @click="false">
            <!-- Outright payment -->
            Product Unavailable
          </button>
        </div>
        <!-- <button  
          :disabled="flutterProcessing"
          class="btn btn-checkout "
          @click="placeOrder()"
        >
          Outright payment (Paystack)
        </button> -->

        <!-- <button
          v-if="isSpecta"
          :disabled="spectaPaying || flutterProcessing"
          @click="payWithMono()"
          class="btn btn-checkout"
        >
          Lease to Own <BtnLoading v-if="spectaPaying" class="btn-loading" />
        </button> -->
        <!-- Comment -->
        <!-- <button > -->
        <!-- <router-link v-else class="btn btn-checkout" to="/take-a-loan">
          Lease to Own
        </router-link> -->
        <!-- </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import Loading from "@/components/Loading.vue";
// import axios from "axios";

import paystack from "vue-paystack";

export default {
  props: ["paymentSummary", "loading", "cartId"],
  data() {
    return {
      currentlyLoading: false,
      cartSending: false,
      spectaPaying: false,
      orderData: {},
      flutterProcessing: false,
      flutterErr: false,
      flutterComplete: false,
      isSpecta: true,
    };
  },
  components: { BtnLoading, Loading, paystack },
  computed: {
    payAmount() {
      return Math.ceil(
        (this.paymentSummary ? this.paymentSummary.total_price : 0) * 100
      );
    },
    getID() {
      return this.paymentSummary ? this.paymentSummary.cart.id : null;
    },
    paystackkey() {
      return this.$store.getters.paystackkey; //paystack public key
    },
    route() {
      return this.$route.name.toLowerCase();
    },
    loggedIn() {
      if (localStorage.getItem("user")) {
        let user = this.CryptoJS.AES.decrypt(
          localStorage.getItem("user"),
          this.$passPhrase
        ).toString(this.CryptoJS.enc.Utf8);
        return JSON.parse(user);
      } else {
        return false;
      }
    },
    subTotal() {
      let sTotal = 0;
      for (const product of this.cartStorage) {
        let tPrice = product.quantity * product.product.price;
        sTotal += tPrice;
      }
      return sTotal;
    },
    cartStorage() {
      return this.$store.getters.cartProducts;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    userData() {
      if (localStorage.getItem("user")) {
        let user = this.CryptoJS.AES.decrypt(
          localStorage.getItem("user"),
          this.$passPhrase
        ).toString(this.CryptoJS.enc.Utf8);
        return JSON.parse(user);
      } else {
        return {};
      }
    },
  },
  watch: {
    orderData() {
      // console.log(this.orderData);
      // console.log('changed');
      // console.log(this.orderData);
      var summ = this.orderData;
      // console.log(summ);
      if (summ.order.total_price > 2000000) {
        this.isSpecta = false;
      } else {
        this.isSpecta = true;
      }
    },
  },
  mounted() {
    // // console.log(this.cartStorage.length);
    // if (!this.cartStorage || this.cartStorage.length === 0) {
    //   // console.log("Running");
    //   // this.syncCart();
    // }
    this.getCartCheckout();
    if (this.$route.query.status) {
      if (this.$route.query.status === "successful") {
        this.flutterProcessing = true;
        this.verifyFlutter(this.$route.query.transaction_id);
      } else {
        this.flutterProcessing = false;
        this.flutterErr = true;
        this.$toast.error(
          "Flutterwave Payment Failed",
          "",
          this.$toastPosition
        );
      }
    }
  },

  methods: {
    verifyFlutter(txnId) {
      let data = {};
      data = this.$helpers.encrypt(JSON.stringify(data));

      data = {
        data,
      };
      let payload = {
        data,
        path: "order/checkout",
      };
      this.$store
        .dispatch("postRequest", payload)
        .then((resp) => {
          // amt = resp.data.data;
          // console.log(resp);

          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;

          let amt = resp.data.data.order.total_price;
          let orid = resp.data.data.order.id;

          let data = {
            amount: amt,
            transaction_id: txnId,
          };
          data = this.$helpers.encrypt(JSON.stringify(data));

          data = {
            data,
          };
          // console.log(data);
          let payload = {
            data,
            path: `flutterwave/payment-callback/${orid}`,
          };
          this.$store
            .dispatch("postRequest", payload)
            .then((respo) => {
              let decr = JSON.parse(this.$helpers.decrypt(respo.data.data));
              respo.data = decr;

              if (respo.data.data.data.status === "successful") {
                this.flutterProcessing = false;
                this.flutterErr = false;
                this.flutterComplete = true;
                this.$toast.info(
                  "Payment Successful",
                  "Your order is being processed",
                  this.$toastPosition
                );
                this.$store.commit("updateCart", []);
                this.$store.commit("paymentSent", true);
                this.$store.commit("handle_loading", false);

                setTimeout(() => {
                  window.open(
                    "https://sterlingbank.eu.qualtrics.com/jfe/form/SV_dik9Ou2jB0zO3Ea",
                    "_self"
                  );

                  this.$store.commit("paymentSent", false);
                }, 2000);
              }
            })
            .catch((err) => {
              // console.log("error= ", err);
              if (err) {
                this.flutterProcessing = false;
                this.flutterErr = true;
                this.$toast.error("Error occured", "", this.$toastPosition);
                this.$store.dispatch("handle_loading", false);
              }
            });
        })
        .catch((err) => {});
      // payment-callback/0e996f98-50b7-4575-9e0f-d55f6618afc8
      // let amt = this.orderData.order.total_price;
      // let data = {
      //   amount: amt,
      //   transaction_id: txnId,
      // };
      // let payload = {
      //   data,
      //   path: "payment-callback/0e996f98-50b7-4575-9e0f-d55f6618afc8",
      // };
      // this.$store
      //   .dispatch("postRequest", payload)
      //   .then((resp) => {
      //     console.log(resp);
      //   })
      //   .catch((err) => {
      //     console.log("error= ", err);
      //     if (err) {
      //       this.flutterProcessing = false;
      //     }
      //   });
    },

    payWithFlutterwave() {
      this.flutterProcessing = true;
      // console.log("WTF is goinng on?");
      // console.log(this.orderData);
      let amt = this.orderData.order.total_price;
      let data = {
        amount: amt,
      };
      data = this.$helpers.encrypt(JSON.stringify(data));

      data = {
        data,
      };
      let payload = {
        data,
        path: "flutterwave/create-payment",
      };
      this.$store
        .dispatch("postRequest", payload)
        .then((resp) => {
          // console.log(resp.data.data.data.link);

          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;

          window.open(resp.data.data.data.link, "_self");
        })
        .catch((err) => {
          console.log("error= ", err);
          if (err) {
            this.flutterProcessing = false;
          }
        });
      //   console.log(data);
      //   console.log(data.amount);
      //   console.log(amt);
      // console.log( "UserID= ", this.loggedIn.user.id);
    },

    getCartCheckout() {
      // alert("Getting ")
      if (this.route == "cart") {
        // this.clearInitialCart(this.userData.user.id);
        // console.log(this.userData);
      } else {
        let data = {};
        // console.log(this.userData.user.phone_number)
        // if(this.userData.user){
        //   data = {
        //     contact_phone: this.userData.user.phone_number,
        //     shipping_address: {
        //       lga: this.userData.customer.address.lga,
        //       state: this.userData.customer.address.state,
        //       street: this.userData.customer.address.street
        //     }
        //   };
        // }
        //iusbu
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };

        let payload = {
          data,
          path: "order/checkout",
        };
        this.$store
          .dispatch("postRequest", payload)
          .then((resp) => {
            let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
            resp.data = decr;
            this.orderData = resp.data.data;
            // console.log(resp);
            // if (this.route == "cart") {
            //   this.clearInitialCart(this.orderData.order.id);
            // }
          })
          .catch((err) => {});
      }
    },
    clearInitialCart(id) {
      let payload = {
        path: `/order/${id}`,
      };
      this.$store
        .dispatch("delRequest", payload)
        .then((resp) => {
          // console.log(resp);
        })
        .catch((err) => {});
    },
    // placeOrder() {
    //   const handler = PaystackPop.setup({
    //     key: this.paystackkey,
    //     email: this.loggedIn.user.email,
    //     amount: this.payAmount,
    //     currency: "NGN",
    //     metadata: {
    //       custom_fields: {
    //         order_id: this.orderData.order.id,
    //       },
    //     },
    //     callback: (code) => {
    //       // this.$toast.info(
    //       //   "Payment Successful",
    //       //   "Your order is being processed",
    //       //   this.$toastPosition
    //       // );
    //       // this.$store.commit("updateCart", []);
    //       // this.$store.commit("paymentSent", true);
    //       this.handleSuccessPayment(code);
    //     },
    //     close: () => {
    //       this.$toast.info(
    //         "Payment",
    //         "Payment process closed",
    //         this.$toastPosition
    //       );
    //     },
    //   });
    //   handler.openIframe();
    // },
    // handleSuccessPayment(code) {
    //   let payload = {
    //     data: {
    //       order_id: this.orderData.order.id,
    //       reference: code.reference,
    //     },
    //     path: "order/hook",
    //   };
    //   this.$store.commit("handle_loading", true);
    //   this.$store
    //     .dispatch("putRequest", payload)
    //     .then(() => {
    //       this.$toast.info(
    //         "Payment Successful",
    //         "Your order is being processed",
    //         this.$toastPosition
    //       );
    //       this.$store.commit("updateCart", []);
    //       this.$store.commit("paymentSent", true);
    //       this.$store.commit("handle_loading", false);
    //     })
    //     .catch(() => {
    //       this.$toast.error("Error occured", "", this.$toastPosition);
    //       this.$store.dispatch("handle_loading", false);
    //     });
    //   // console.log(code);
    // },
    payWithSpecta() {
      // console.log(this.paymentSummary);
      // this.paymentSummary.total_price < 20000
      if (this.paymentSummary.total_price < 20000) {
        this.$toast.info(
          "Specta",
          "Total amount must be ₦20,000 and above to access our loan service.",
          this.$toastPosition
        );
      } else {
        const items = this.paymentSummary.cart.items.map((item) => {
          return item.product.name;
        });
        let data = {
          reference: `${this.reference}${this.paymentSummary.cart.id}`,
          description: `Payment for ${items}`,
          amount: Math.ceil(this.paymentSummary.total_price),
          order_id: this.orderData.order.id,
        };
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };
        let payload = {
          data,
          path: `lease/createPayment`,
        };
        this.spectaPaying = true;
        this.$store
          .dispatch("postRequest", payload)
          .then((resp) => {
            // console.log(resp);
            let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
            resp.data = decr;
            // alert(resp);
            this.spectaPaying = false;
            if (resp.data.data) {
              window.location = resp.data.data;
            }
          })
          .catch((err) => {
            this.spectaPaying = false;
            if (err.response) {
              let decr = JSON.parse(
                this.$helpers.decrypt(err.response.data.data)
              );
              err.response.data = decr;
              this.$toast.info(
                "Specta",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Specta",
                "Loan payment is down, please contact support.",
                this.$toastPosition
              );
            }
          });
      }
    },
    payWithMono() {
      this.spectaPaying = true;
      let payload = {
        path: `mono/get-link-account`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          this.spectaPaying = false;
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          console.log(resp);
          // return
          if (resp.data.data) {
            window.location = resp.data.data;
          }
          // let { orders } = resp.data.data;
          // let { customer } = resp.data.data;
          // // console.log(customer);
          // this.orders = orders;
          // this.customer = customer;
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            console.log(err.response);
            // this.$toast.info(
            //   "Profile data",
            //   err.response.data.message,
            //   this.$toastPosition
            // );
            // this.$toast.info(
            //   "Profile data",
            //   "Session Expired. Plese Login Again",
            //   this.$toastPosition
            // );
            // this.logout();
          } else {
            // this.$toast.info(
            //   "Profile data",
            //   "Unable to load Profile data, Please Login Again",
            //   this.$toastPosition
            // );
            // this.logout();
          }
          this.spectaPaying = false;
        });
    },
    // syncCart() {
    //   this.cartSending = true;

    //   let products = [];
    //   for (const product of this.cartStorage) {
    //     let nProduct = product.product;
    //     nProduct.quantity = parseInt(product.quantity);
    //     nProduct.product_id = product.product.id;
    //     // delete nProduct.id;
    //     delete nProduct.__v;

    //     products.push(nProduct);
    //   }
    //   // console.log(products);
    //   // console.log(products, "cart products");
    //   let data = {
    //     products,
    //   };

    //   data = this.$helpers.encrypt(JSON.stringify(data));

    //   data = {
    //     data,
    //   };

    //   let payload = {
    //     data,
    //     path: "/cart",
    //   };
    //   this.$store
    //     .dispatch("postRequest", payload)
    //     .then((resp) => {
    //       this.cartSending = false;
    //       // console.log("cc");
    //       // this.$router.push("/checkout");
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         let decr = JSON.parse(
    //           this.$helpers.decrypt(err.response.data.data)
    //         );
    //         err.response.data = decr;
    //         this.$toast.info(
    //           "Cart",
    //           err.response.data.message,
    //           this.$toastPosition
    //         );
    //       } else {
    //         this.$toast.info(
    //           "Cart",
    //           "Unable to send product items, please try again",
    //           this.$toastPosition
    //         );
    //       }
    //       this.cartSending = false;
    //     });
    // },
    redirectCheckout() {
      if (this.loggedIn) {
        this.cartSending = true;

        let payload = {
          path: `/order/${this.userData.user.id}`,
        };
        this.$store
          .dispatch("delRequest", payload)
          .then((resp) => {
            let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
            resp.data = decr;
            this.orderData = resp.data.data;
            // console.log(resp);
            let products = [];
            for (const product of this.cartStorage) {
              let nProduct = product.product;
              nProduct.quantity = parseInt(product.quantity);
              nProduct.product_id = product.product.id;
              // delete nProduct.id;
              delete nProduct.__v;

              products.push(nProduct);
            }
            // console.log(products);
            // console.log(products, "cart products");
            let data = {
              products,
            };

            data = this.$helpers.encrypt(JSON.stringify(data));

            data = {
              data,
            };

            let payload = {
              data,
              path: "/cart",
            };
            this.$store
              .dispatch("postRequest", payload)
              .then((resp) => {
                this.cartSending = false;
                this.$router.push("/checkout");
              })
              .catch((err) => {
                if (err.response) {
                  let decr = JSON.parse(
                    this.$helpers.decrypt(err.response.data.data)
                  );
                  err.response.data = decr;
                  this.$toast.info(
                    "Cart",
                    err.response.data.message,
                    this.$toastPosition
                  );
                } else {
                  this.$toast.info(
                    "Cart",
                    "Unable to send product items, please try again",
                    this.$toastPosition
                  );
                }
                this.cartSending = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$router.push("/login?cart=true");
      }
    },
  },
};
</script>
<style scoped>
.disclaimer-font {
  font-size: 0.8rem;
}
div.mt--60 {
  margin-top: -60px;
}
div.mt--60 p {
  width: 70%;
  text-align: center;
  margin: auto;
}
button:disabled {
  cursor: not-allowed;
}
p.err {
  color: red;
  margin-bottom: 0;
  margin-top: 30px;
}
p.success {
  color: #275f13;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 30px;
}
</style>
